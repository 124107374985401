<template>
	<div class="is-relative">
		<Navbar></Navbar>
		<div class="kompozit-container">
			<img alt="arrow-left" class="arrow-left hide-on-mobile-tablet" src="../../assets/chevron-right-white.svg"
			@click="goBack"/>
			<div class="">
				<div class="case-study-title mb-30">Kompozit - Field service management</div>
				<div class="case-study-text--modified">
					Kompozit is a field service management solution for documenting the work of service teams in an easy and efficient way.
				</div>
			</div>
		</div>

		<div class="case-study-second-panel-container">
			<div class="kompozit-second-panel-box">
				<h2 class="mb-30">Kompoz.it</h2>
				<p class="width-40p"> Kompozit is Furthers’ first in-house product, created with the purpose of making fieldwork manageable
					through a single digital platform. Our goal was to build an app that is easy to use by anyone working
					in the field, and that simplifies accounting for both customers and employees. The end product is two-part.
					On the one hand, there is a web application which allows for the creation and management of jobs,
					and is also responsible for generating detailed reports. On the other hand, there is a cross-platform
					mobile application which can be used in the field to accurately document the jobs done.
				</p>
			</div>

			<img alt="romfriend-screen" class="flatco-screen hide-on-mobile-tablet" src="../../assets/case-study/kompozit-screen.svg"/>
		</div>

		<div class="third-panel-container">
			<div class="overview-title">Project Overview</div>
			<div class="third-panel-grid">
				<div>
					<div class="overview-subtitle">CHALLENGE</div>
					<div class="overview-text mb-30">
						<ul>
							<li class="overview-text">
								Product design from zero to handover to development
							</li>
						</ul>
					</div>

					<div class="overview-subtitle">SOLUTION</div>
					<ol>
						<li class="overview-text">Ideate on product name</li>
						<li class="overview-text">Design the logo and identity</li>
						<li class="overview-text">Design app</li>
						<li class="overview-text">Design admin</li>
						<li class="overview-text">Design landing page</li>
					</ol>

				</div>

				<div>
					<div class="overview-subtitle">ROLE</div>
					<div class="overview-text mb-30">UX/UI Designer</div>
					<div class="overview-subtitle">TIME</div>
					<div class="overview-text mb-30">6 weeks</div>
					<div class="overview-subtitle">TASK</div>
					<div class="overview-text mb-30">UI design, brand identity</div>
					<div class="overview-subtitle">TOOL</div>
					<div class="overview-text mb-30">Figma, Adobe Illustrator</div>
				</div>
			</div>
		</div>

		<div class="design-container">
			<h2 class="design-process-title">Design process</h2>
			<div class="hide-on-desktop">
				<div class="carousel-container">
					<div class="carousel" :style="{ transform: `translateX(-${currentIndex * 100}%)` }">
						<div class="carousel-item" v-for="(item, index) in items" :key="index">
							<img :alt="item.alt" class="category-icon" :src="require(`@/assets/icons/${item.src}`)" />
							<div class="design-type-title">{{ item.title }}</div>
							<div class="design-type-description" v-for="(desc, i) in item.descriptions" :key="i">{{ desc }}</div>
						</div>
					</div>

					<img alt="arrow-left" class="carousel-control left" src="../../assets/arrow-down.svg" @click="prevSlide"/>
					<img alt="arrow-right" class="carousel-control right" src="../../assets/arrow-down.svg" @click="nextSlide"/>
				</div>
			</div>

			<div class="hide-on-mobile-tablet">
				<div class="design-process-container">
					<div class="ux-ui-design-item">
						<img alt="discovery" class="category-icon" src="../../assets/icons/discovery.svg"/>
						<div class="design-type-title">
							Discovery
						</div>
						<div class="design-type-description">Workshops</div>
						<div class="design-type-description">User interviews</div>
					</div>

					<div class="ux-ui-design-item">
						<img alt="research" class="category-icon" src="../../assets/icons/research.svg"/>
						<div class="design-type-title">
							Research
						</div>
						<div class="design-type-description">Business goals</div>
						<div class="design-type-description">Users goals</div>
					</div>

					<div class="ux-ui-design-item">
						<img alt="research" class="category-icon" src="../../assets/icons/ideation.svg"/>
						<div class="design-type-title">
							Ideation
						</div>
						<div class="design-type-description">Brainstorming</div>
						<div class="design-type-description">Sticky notes</div>
					</div>

					<div class="ux-ui-design-item">
						<img alt="design" class="category-icon" src="../../assets/icons/design.svg"/>
						<div class="design-type-title">Design
						</div>
						<div class="design-type-description">Logo & Brand identity</div>
						<div class="design-type-description">High-fidelity wireframes</div>
						<div class="design-type-description">User interface</div>
					</div>
					<div class="dashed-line"></div>
				</div>
			</div>
		</div>

		<div class="discovery-research-container">
			<h2 class="mb-30">Discovery & Research</h2>
			<div class="discovery-research-grid">
				<div class="discovery-research-width">
					<div class="design-type-title">Unveiling Client Needs for Field Service Management App</div>

					<p>In the initial discovery stage with the Further Team, we conducted five user interviews to understand
						the specific needs of potential clients in the field service industry. Focused on developing a Field Service Management (FSM)
						application, participants included field technicians and service managers. Armed with a set of carefully crafted questions,
						we delved into their workflows, pain points, and aspirations for an ideal FSM solution.
						<br><br>
						Our team meticulously documented observations, pain points, and recurring themes, providing a qualitative foundation for
						subsequent design and development phases. This valuable data ensures our FSM app is not only functional but tailored to
						address the specific needs uncovered during this insightful discovery stage. The user interviews serve as a compass,
						guiding our team toward creating a solution that resonates with end-users in the field service industry.
					</p>

					<div class="design-type-title">Market research</div>

					<p>In our Field Service Management (FSM) market research, we analyzed existing solutions, competitive offerings,
						and emerging trends to validate insights from user interviews and align with industry demands.
						By scrutinizing user feedback, we identified crucial pain points and aspirations, gaining a comprehensive understanding
						of the market landscape. This research strategically positions our project, enabling us to develop an FSM solution that
						addresses current industry needs and anticipates future trends in this dynamic field.
					</p>
				</div>

				<div class="width-40p">
					<img alt="workflow" class="workflow center" src="../../assets/case-study/kompozit-workflow.svg"/>
				</div>
			</div>
		</div>

		<div class="ideation-container">
			<h2 class="mb-30">Ideation</h2>
			<div class="design-type-title">Collaborative Brainstorming and Feature Prioritization</div>
			<div class="ideation-grid">
				<p class="mr-50">
					During the ideation stage, our team fostered a dynamic and collaborative environment by employing a creative approach.
					Armed with sticky notes, we unleashed a torrent of features and ideas for our project. The visual and tactile nature of
					the sticky notes allowed for spontaneous contributions, ensuring a diverse range of perspectives.
					<br><br>
					To distill and prioritize these ideas, we engaged in a democratic voting process.
					Team members cast their votes for the features they deemed most impactful and viable.
					This interactive method not only streamlined our focus but also empowered the team to collectively shape the direction of the project.
					The outcome is a refined set of prioritized features that resonates with the shared vision and enthusiasm of the entire team.
				</p>

				<div class="sticky-note-container">
					<img alt="workflow" class="sticky-note " src="../../assets/case-study/kompozit-sticky-note1.svg"/>
					<img alt="workflow" class="sticky-note ml-20" src="../../assets/case-study/kompozit-sticky-note2.svg"/>
				</div>
			</div>
		</div>

		<div class="discovery-research-container">
			<h2 class="mb-30">Design</h2>
			<div class="design-type-title">Color & Typography</div>

			<div class="color-grid">
				<img alt="color1" class="color-images" src="../../assets/case-study/kompozit-primary.svg"/>
				<img alt="color2" class="color-images " src="../../assets/case-study/kompozit-secondary.svg"/>
				<img alt="color3" class="color-images " src="../../assets/case-study/kompozit-text.svg"/>
			</div>

			<div class="kompozit-text-grid">
				<div class="text-center align-center">
					<img alt="text" class="nunito" src="../../assets/case-study/kompozit-nunito.svg"/>
				</div>
				<div>
					<img alt="text" class="kompozit-text" src="../../assets/case-study/kompozit-text-style.svg"/>
				</div>
			</div>

			<div class="design-type-title">Logo</div>
			<img alt="logos" class="kompozit-logo" src="../../assets/case-study/kompozit-logos.svg"/>

			<div class="design-type-title">Wireframing</div>
		</div>

		<div class="mt-30">
			<img alt="wireframig" class="wireframing-logos" src="../../assets/case-study/kompozit-wireframing.svg"/>
		</div>

		<div class="user-interface-container">
			<h2 class="mb-30 is-gray width-fit">App <br> User Interface</h2>
			<img alt="wireframig" class="kompozit-logos hide-on-mobile-tablet" src="../../assets/case-study/user-interface-screen.svg"/>
			<img alt="wireframig" class="kompozit-logos hide-on-desktop" src="../../assets/case-study/user-interface-screen-mobile.svg"/>
		</div>

		<div class="kompozit-landing">
			<img alt="landing-page" class="kompozit-image" src="../../assets/case-study/kompozit-landing-page.svg"/>
		</div>

		<div class="kompozit-last-panel"></div>


		<div class="footer-container mb-30">
			<router-link class="is-flex pointer" to="/case-study/contractual">
				<img alt="chevron-left" class="footer-arrow" src="../../assets/arrow-right.png"/>
			</router-link>
			<div class="thanks">Thanks for watching!</div>
			<router-link class="is-flex pointer" to="/case-study/romanian-friend">
				<img alt="chevron-right" class="footer-arrow" src="../../assets/arrow-left.png"/>
			</router-link>
		</div>

		<img alt="chevron-up" class="footer-arrow-up" src="../../assets/arrow-up.png" v-on:click="goToTop"/>

	</div>
</template>

<script lang="ts">
import Navbar from "@/components/Navbar.vue";
import {defineComponent} from "vue";

export default defineComponent({
	components: {
		Navbar
	},
	data() {
		return {
			items: [
				{
					alt: "discovery",
					src: "discovery.svg",
					title: "Discovery",
					descriptions: ["Workshops", "User interviews"],
				},
				{
					alt: "research",
					src: "research.svg",
					title: "Research",
					descriptions: ["Business goals", "Users goals"],
				},
				{
					alt: "ideation",
					src: "ideation.svg",
					title: "Ideation",
					descriptions: ["Brainstorming", "Sticky notes"],
				},
				{
					alt: "design",
					src: "design.svg",
					title: "Design",
					descriptions: ["Logo & Brand identity", "High-fidelity wireframes", "User interface"],
				},
			],
			currentIndex: 0,
		}
	},
	methods: {
		goBack() {
			this.$router.go(-1);
		},
		goToTop() {
			window.scrollTo(0, 0)
		},
		nextSlide() {
			this.currentIndex = (this.currentIndex + 1) % this.items.length;
		},
		prevSlide() {
			this.currentIndex = (this.currentIndex - 1 + this.items.length) % this.items.length;
		},
	},
});
</script>

<style scoped>

</style>
